import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Grid, Stack } from "@mui/material"
import OnBoarding from "../../components/OnBoarding"
import { ReactComponent as TestSVG } from "../../assets/images/card-test.svg"
import AddTestButton from "./AddTestButton"
import {
  selectAllTests,
  selectPreparedTestsLength,
  selectImportedTestsLength,
  selectAllProgressionAnswers,
  selectAllProgression
} from "../../modules/formative/selectors"
import TitleAndDescription from "../../components/TitleAndDescription"
import FormativeQuestionsTable from "../../components/FormativeQuestionsTable"
import {
  FormativeTestMenuOptions,
  FormativeTest,
  CreateFormativeTest,
  EditFormativeTest,
  FormativeSession,
  FormativeQuestionsWithStudent
} from "../../modules/formative/types"
import {
  startFormativeTest,
  stopFormativeTest,
  redoFormativeTest,
  createFormativeTest,
  removeFormativeTest,
  editFormativeTest
} from "../../modules/formative/actions"
import Dialog from "../../components/Dialog"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import MobileOrDesktop from "../../containers/MobileOrDesktop"
import { StyledDivider } from "./StyledFormativeQuestions"
import FormativeQuestionsTableAsList from "../../components/FormativeQuestionsTableAsList"
import { formativeStatusSortWeight } from "./filterSettings"
import moment, { Moment } from "moment"
import StopTestDialog from "./StopTestDialog"
import RedoTestDialog, { RedoFormValues } from "./RedoTestDialog"
import { Members, Member } from "../../modules/members/types"
import { getLatestSession, parseAnswersForTest } from "./helpers"
import { selectStudentsWithProduct } from "../../modules/members/selectors"
import * as routerActions from "../../modules/router/actions"
import {
  selectAllGroups,
  selectClassroomId,
  selectClassroomSchoolUnitCode
} from "../../modules/classroom/selectors"
import FormativeTestImportDialogContent from "../../components/FormativeTestImportDialogContent"
import { ProgressForBar } from "../../components/FormativeQuestionsTable/types"
import ExercisesProgressDialog from "../../components/ExercisesProgressDialog"
import { MembersWithOnlineStatus } from "../Exercises/store/types"
import FormativeResultsDialog from "../../components/FormativeResultsDialog"
import { getMembersWithAnswers } from "../FormativeQuestionsResult/helpers"
import {
  selectActiveProductFeatures,
  selectProducts,
  selectActiveProductName
} from "../../modules/products/selectors"
import { PossibleStatusActions } from "./types"
import FormativeImportDialog from "../../components/FormativeImportDialog/FormativeImportDialog"
import TipsBanner from "../../components/Banners/TipsBanner"
import { selectAuthenticatedUser } from "../../modules/users/selectors"
import { updateUserSettingRequest } from "../../modules/users/actions"
import { SETTING } from "../../modules/users/constants"
import FullWidthContainer from "../../containers/PageContainers/FullWidthContainer"
const FormativeQuestions = () => {
  const [confirmStartDialog, setConfirmStartDialog] = useState<null | {
    testName: string
    testId: number
  }>()
  const [confirmRemoveDialog, setConfirmRemoveDialog] = useState<null | {
    testName: string
    testId: number
  }>()
  const [confirmStopDialog, setConfirmStopDialog] = useState<null | {
    testName: string
    testId: number
  }>()

  const [fullScreenResultDialog, setFullScreenResultDialog] = useState<null | {
    questions: FormativeQuestionsWithStudent
  }>(null)

  const [confirmRedoDialog, setConfirmRedoDialog] = useState<null | {
    testName: string
    testId: number
    students: Members
    numberOfSessions: number
    test: FormativeTest
    session: FormativeSession
  }>(null)

  const [progressDialogData, setProgressDialogData] = useState<{
    done: Member[]
    notStarted: Member[]
    inProgress: Member[]
    title: string
  } | null>(null)

  const [openImportTestDialog, setOpenImportTestDialog] = useState<
    "prepared" | "import" | null
  >(null)

  const progress = useSelector(selectAllProgression)
  const tests = useSelector(selectAllTests)
  const classroomId = useSelector(selectClassroomId)
  const students = useSelector(selectStudentsWithProduct)
  const answers = useSelector(selectAllProgressionAnswers)
  const preparedTestsLength = useSelector(selectPreparedTestsLength)
  const productFeatures = useSelector(selectActiveProductFeatures)
  const activeProductName = useSelector(selectActiveProductName)
  const products = useSelector(selectProducts)
  const importableTestsLength = useSelector(selectImportedTestsLength)
  const groups = useSelector(selectAllGroups)
  const user = useSelector(selectAuthenticatedUser)
  const classroomSchoolUnitCode = useSelector(selectClassroomSchoolUnitCode)

  const dispatch = useDispatch()
  const renderOnBoarding = () => {
    return (
      <>
        {!groups?.length && user?.settings.settingShowTipFormativeTest && (
          <TipsBanner
            tipHeaderText="För att kunna skicka test"
            tipContentText="Om du vill skicka test till elever i klassrummet behöver du ingå som lärare i en grupp med elever. Du kan gå med i en av skolans befintliga grupper eller skapa en helt ny grupp där du lägger till dig själv och de elever du vill följa."
            actions={
              <>
                <Button
                  component="a"
                  href={`${process.env.REACT_APP_BOOKSHELF_URL}/${classroomSchoolUnitCode}/admin/grupper`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  Administrera grupper
                </Button>
                <Button color="inherit" onClick={onHideTip}>
                  Dölj
                </Button>
              </>
            }
          />
        )}
        {productFeatures.includes("FORMATIVE_TESTS") && (
          <>
            <AddTestButton
              showImportTest={Boolean(importableTestsLength > 0)}
              showPreparedTest={Boolean(preparedTestsLength > 0)}
              onBoarding={true}
              openPreparedTestDialog={onOpenPreparedTestDialog}
              openImportTestDialog={onOpenImportTestDialog}
            />
          </>
        )}
        <OnBoarding {...renderCardContent()} />
      </>
    )
  }

  const onHideTip = () =>
    dispatch(
      updateUserSettingRequest(
        user?.studliId as number,
        SETTING.settingShowTipFormativeTest,
        true
      )
    )

  const renderTitleAndDescription = () => {
    const paragraphs = [
      `Använd formativa frågor för att snabbt stämma av hur klassen ligger till i sin progression. Eleverna får snabbt tillgång till frågorna och det blir enklare för dig att följa upp. Du får direkt en översikt över allas resultat. Du kan också välja att titta på enskilda elevers svar.`
    ]

    return (
      <>
        {!groups?.length && user?.settings.settingShowTipFormativeTest && (
          <TipsBanner
            tipHeaderText="För att kunna skicka test"
            tipContentText="Om du vill skicka test till elever i klassrummet behöver du ingå som lärare i en grupp med elever. Du kan gå med i en av skolans befintliga grupper eller skapa en helt ny grupp där du lägger till dig själv och de elever du vill följa."
            actions={
              <>
                <Button
                  component="a"
                  href={`${process.env.REACT_APP_BOOKSHELF_URL}/${classroomSchoolUnitCode}/admin/grupper`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  Administrera grupper
                </Button>
                <Button color="inherit" onClick={onHideTip}>
                  Dölj
                </Button>
              </>
            }
          />
        )}
        <AddTestButton
          showImportTest={Boolean(importableTestsLength > 0)}
          showPreparedTest={Boolean(preparedTestsLength > 0)}
          onBoarding={false}
          openPreparedTestDialog={onOpenPreparedTestDialog}
          openImportTestDialog={onOpenImportTestDialog}
        />
        <TitleAndDescription
          title="Följ upp dina test"
          paragraphs={paragraphs}
        />
      </>
    )
  }

  const onProgressDialogClick = (
    progressToShow: ProgressForBar & { [key: string]: number[] },
    title: string
  ) => {
    const dialogData: any = {
      done: [],
      inProgress: [],
      notStarted: [],
      title
    }
    // eslint-disable-next-line
    Object.keys(progressToShow).map(key => {
      progressToShow[key].forEach(memberId => {
        const progressStudent = students.find(
          student => student.studliId === memberId
        )
        if (progressStudent) {
          dialogData[key].push(progressStudent)
        }
      })
    })
    setProgressDialogData(dialogData)
  }

  const renderCardContent = () => {
    return productFeatures.includes("FORMATIVE_TESTS")
      ? {
          title: "Lägg till test med formativa frågor",
          body: [
            `Använd formativa frågor för att snabbt stämma av hur klassen ligger till i sin progression. Eleverna får snabbt tillgång till frågorna och det blir enklare för dig att följa upp. Du får direkt en översikt över allas resultat. Du kan också välja att titta på enskilda elevers svar.`
          ],
          image: <TestSVG />
        }
      : {
          title: "Formativa frågor",
          body: [
            `I ${activeProductName} finns inga formativa frågor. Byt till ${
              products
                .filter(p => p.features.includes("FORMATIVE_TESTS"))
                .map(p => p.niceName || p.title)[0]
            } för att visa formativa frågor.`
          ],
          image: <TestSVG />
        }
  }

  const onOpenImportTestDialog = () => {
    setOpenImportTestDialog("import")
  }

  const onOpenPreparedTestDialog = () => {
    setOpenImportTestDialog("prepared")
  }

  const onFormativeTestMenuClick = (
    type: FormativeTestMenuOptions,
    testId: FormativeTest["id"]
  ) => {
    const formativeTest: FormativeTest | undefined = tests.find(
      test => test.id === testId
    )

    if (formativeTest) {
      const latestSession = getLatestSession(formativeTest.sessions)
      switch (type) {
        case "togglePrivate":
          let status = "published"
          if (formativeTest.status === "draft") {
            status = "draft"
          }
          if (formativeTest.status === "archived") {
            status = "archived"
          }
          const toggleTest = {
            id: formativeTest.id,
            title: formativeTest.title,
            status: status as EditFormativeTest["status"],
            instruction: latestSession.instruction,
            questions: formativeTest.questions.map(question => question.id),
            endQuestion: formativeTest.endQuestion,
            members: latestSession.members,
            isPublic: !formativeTest.isPublic
          }
          dispatch(editFormativeTest({ test: toggleTest, echo: "" }))
          break
        case "activate":
          const activateTest: EditFormativeTest = {
            id: formativeTest.id,
            title: formativeTest.title,
            status: "published",
            instruction: latestSession.instruction,
            isPublic: formativeTest.isPublic,
            questions: formativeTest.questions.map(question => question.id),
            endQuestion: formativeTest.endQuestion,
            members: latestSession.members
          }
          dispatch(editFormativeTest({ test: activateTest, echo: "activate" }))
          break
        case "archive":
          const archiveTest: EditFormativeTest = {
            id: formativeTest.id,
            title: formativeTest.title,
            status: "archived",
            instruction: latestSession.instruction,
            isPublic: formativeTest.isPublic,
            questions: formativeTest.questions.map(question => question.id),
            endQuestion: formativeTest.endQuestion,
            members: latestSession.members
          }
          dispatch(editFormativeTest({ test: archiveTest, echo: "archive" }))
          break
        case "createCopy":
          const copyTest: CreateFormativeTest = {
            title: `Kopia av: ${formativeTest.title}`,
            status: formativeTest.status === "draft" ? "draft" : "published",
            instruction: formativeTest.instruction,
            questions: formativeTest.questions.map(question => question.id),
            endQuestion: formativeTest.endQuestion,
            members: []
          }

          dispatch(createFormativeTest(copyTest))
          break
        case "edit":
          dispatch(
            routerActions.gotoRoute(
              routerActions.ROUTE_FORMATIVA_FRAGOR_REDIGERA,
              { classroomId, testId }
            )
          )
          break
        case "fullscreen":
          const parsedAnswers = parseAnswersForTest(answers, formativeTest.id)
          const questionsWithAnswers = getMembersWithAnswers(
            latestSession,
            formativeTest,
            parsedAnswers
          )

          setFullScreenResultDialog({ questions: questionsWithAnswers })
          break
        case "remove":
          setConfirmRemoveDialog({
            testName: formativeTest.title,
            testId: formativeTest.id
          })
          break
        case "start":
          setConfirmStartDialog({
            testName: formativeTest.title,
            testId: formativeTest.id
          })
          break
        case "stop":
          setConfirmStopDialog({
            testName: formativeTest.title,
            testId: formativeTest.id
          })
          break
        case "doAgain":
          const studentsOnTest = formativeTest.participantsInfo
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
            .filter(student =>
              latestSession.members.some(
                memberInSession => memberInSession === student.studliId
              )
            ) as unknown as Members
          setConfirmRedoDialog({
            testName: formativeTest.title,
            testId: formativeTest.id,
            students: studentsOnTest,
            numberOfSessions: formativeTest.sessions.length,
            test: formativeTest,
            session: latestSession
          })
          break
      }
    }
  }

  const onActionStatusClicked = (
    event: any,
    status: PossibleStatusActions,
    testName: string,
    id: number
  ) => {
    event.stopPropagation()
    switch (status) {
      case "published":
        setConfirmStartDialog({ testName, testId: id })
        break
      case "ongoing":
        setConfirmStopDialog({ testName, testId: id })
        break
      case "stopped":
        const test = tests.find(test => test.id === id)
        if (test) {
          const latestSession = getLatestSession(test.sessions)
          const studentsOnTest = test.participantsInfo
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
            .filter(student =>
              latestSession.members.some(
                memberInSession => memberInSession === student.studliId
              )
            ) as unknown as Members
          setConfirmRedoDialog({
            testName,
            testId: id,
            students: studentsOnTest,
            numberOfSessions: test.sessions.length,
            test,
            session: latestSession
          })
        }
        break
      default:
    }
  }

  const onStartTest = (id: number) => {
    setConfirmStartDialog(null)
    dispatch(startFormativeTest({ testId: id }))
  }

  const onRemoveTest = (id: number) => {
    setConfirmRemoveDialog(null)
    dispatch(removeFormativeTest({ testId: id }))
  }

  const onStopTest = (id: number, endTime: Moment) => {
    setConfirmStopDialog(null)
    dispatch(stopFormativeTest({ testId: id, stopAt: endTime }))
  }

  const onRedoTest = (values: RedoFormValues) => {
    setConfirmRedoDialog(null)
    dispatch(
      redoFormativeTest({
        testId: values.id,
        instruction: JSON.stringify(values.instruction),
        members: values.members,
        startNow: values.saveType === "startNow"
      })
    )
  }

  const goToTestResult = (testId: number) => {
    const foundTest = tests.find(test => test.id === testId)
    if (!foundTest) {
      return
    }
    dispatch(
      routerActions.gotoRoute(routerActions.ROUTE_FORMATIVA_FRAGOR_INFO, {
        classroomId,
        testId
      })
    )
  }

  const archivedTests = tests.filter(test => test.status === "archived")
  const notArchivedTests = tests
    .filter(test => test.status !== "archived")
    .sort(
      (a, b) =>
        formativeStatusSortWeight.indexOf(a.status) -
          formativeStatusSortWeight.indexOf(b.status) ||
        moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()
    )

  return (
    <FullWidthContainer useMargin role="main">
      {tests.length === 0 ? (
        renderOnBoarding()
      ) : (
        <>
          <Stack maxWidth="lg"> {renderTitleAndDescription()}</Stack>
          <Grid item>
            <MobileOrDesktop breakAt="md">
              {{
                desktop: (
                  <>
                    <FormativeQuestionsTable
                      onActionStatusClicked={onActionStatusClicked}
                      onMenuClick={onFormativeTestMenuClick}
                      title="Klassens test"
                      tests={notArchivedTests}
                      onProgressDialogClick={onProgressDialogClick}
                      onRowClick={goToTestResult}
                      progress={progress}
                      members={students}
                      showFilter
                      type="class"
                    />
                    <StyledDivider />
                    <FormativeQuestionsTable
                      onActionStatusClicked={onActionStatusClicked}
                      onMenuClick={onFormativeTestMenuClick}
                      title="Arkiverade test"
                      tests={archivedTests}
                      onProgressDialogClick={onProgressDialogClick}
                      onRowClick={goToTestResult}
                      progress={progress}
                      members={students}
                      type="class"
                    />
                  </>
                ),
                mobile: (
                  <>
                    <FormativeQuestionsTableAsList
                      onActionStatusClicked={onActionStatusClicked}
                      onMenuClick={onFormativeTestMenuClick}
                      title="Klassens test"
                      tests={notArchivedTests}
                      onProgressDialogClick={onProgressDialogClick}
                      onRowClick={goToTestResult}
                      progress={progress}
                      showFilter
                      type="class"
                    />
                    <StyledDivider />
                    <FormativeQuestionsTableAsList
                      onActionStatusClicked={onActionStatusClicked}
                      onMenuClick={onFormativeTestMenuClick}
                      title="Arkiverade test"
                      tests={archivedTests}
                      onProgressDialogClick={onProgressDialogClick}
                      onRowClick={goToTestResult}
                      progress={progress}
                      type="class"
                    />
                  </>
                )
              }}
            </MobileOrDesktop>
          </Grid>
          {confirmStartDialog && (
            <Dialog
              onClose={() => setConfirmStartDialog(null)}
              maxWidth="xs"
              fullWidth
              open
              role="dialog"
              aria-modal="true"
              aria-labelledby="start-dialog-title"
              aria-describedby="start-dialog-description"
            >
              <ConfirmationDialog
                title={`Starta ${confirmStartDialog.testName}`}
                body="När testet startas blir det tillgängligt i elevernas produkter."
                onCancel={() => setConfirmStartDialog(null)}
                confirmLabel="Starta test"
                cancelLabel="Avbryt"
                onConfirm={() => onStartTest(confirmStartDialog.testId)}
                ariaTitleId="start-dialog-title"
                ariaDescId="start-dialog-description"
              />
            </Dialog>
          )}
          {confirmRemoveDialog && (
            <Dialog
              onClose={() => setConfirmRemoveDialog(null)}
              maxWidth="xs"
              fullWidth
              open
              role="dialog"
              aria-modal="true"
              aria-labelledby="ta-bort-dialog-title"
              aria-describedby="ta-bort-dialog-description"
            >
              <ConfirmationDialog
                title={`Ta bort ${confirmRemoveDialog.testName}?`}
                body="När testet tagits bort försvinner det helt från klassrummet. Om det skickats ut till elever tas det även bort från deras lista över tidigare test."
                onCancel={() => setConfirmRemoveDialog(null)}
                confirmLabel="Ta bort test"
                cancelLabel="Avbryt"
                onConfirm={() => onRemoveTest(confirmRemoveDialog.testId)}
                ariaTitleId="ta-bort-dialog-title"
                ariaDescId="ta-bort-dialog-description"
              />
            </Dialog>
          )}
          {confirmStopDialog && (
            <Dialog
              onClose={() => setConfirmStopDialog(null)}
              maxWidth="xs"
              fullWidth
              open
              role="dialog"
              aria-modal="true"
              aria-labelledby="stop-dialog-title"
              aria-describedby="stop-dialog-description"
            >
              <StopTestDialog
                testName={confirmStopDialog.testName}
                testId={confirmStopDialog.testId}
                onCancel={() => setConfirmStopDialog(null)}
                onSubmit={onStopTest}
              />
            </Dialog>
          )}
          {confirmRedoDialog && (
            <Dialog
              onClose={() => setConfirmRedoDialog(null)}
              maxWidth="md"
              fullWidth
              open
              role="dialog"
              aria-modal="true"
              aria-labelledby="redo-dialog-title"
              aria-describedby="redo-dialog-description"
            >
              <RedoTestDialog
                testName={confirmRedoDialog.testName}
                testId={confirmRedoDialog.testId}
                onCancel={() => setConfirmRedoDialog(null)}
                onRedoTest={onRedoTest}
                students={confirmRedoDialog.students}
                test={confirmRedoDialog.test}
                session={confirmRedoDialog.session}
                numberOfSessions={confirmRedoDialog.numberOfSessions}
              />
            </Dialog>
          )}
        </>
      )}
      {openImportTestDialog === "prepared" && (
        <Dialog
          onClose={() => setOpenImportTestDialog(null)}
          maxWidth="sm"
          fullWidth
          open={openImportTestDialog === "prepared"}
          role="dialog"
          aria-modal="true"
          aria-labelledby="import-dialog-title"
          aria-describedby="import-dialog-description"
        >
          <FormativeTestImportDialogContent
            type={openImportTestDialog}
            onClose={() => setOpenImportTestDialog(null)}
          />
        </Dialog>
      )}

      {openImportTestDialog === "import" && (
        <FormativeImportDialog onClose={() => setOpenImportTestDialog(null)} />
      )}
      {progressDialogData !== null && (
        <Dialog
          onClose={() => setProgressDialogData(null)}
          open={progressDialogData !== null}
          maxWidth="sm"
          fullWidth
        >
          <ExercisesProgressDialog
            onClose={() => setProgressDialogData(null)}
            inProgress={
              progressDialogData.inProgress as MembersWithOnlineStatus
            }
            done={progressDialogData.done as MembersWithOnlineStatus}
            notStarted={
              progressDialogData.notStarted as MembersWithOnlineStatus
            }
            type="formativeQuestions"
            title={progressDialogData.title}
          />
        </Dialog>
      )}
      {fullScreenResultDialog !== null && (
        <Dialog
          onClose={() => setFullScreenResultDialog(null)}
          open={fullScreenResultDialog !== null}
          fullScreen
          fullWidth
        >
          <FormativeResultsDialog
            onClose={() => setFullScreenResultDialog(null)}
            questions={fullScreenResultDialog.questions}
          />
        </Dialog>
      )}
    </FullWidthContainer>
  )
}

export default FormativeQuestions
