import React from "react"
import { CardActions, Typography } from "@mui/material"
import { ReactComponent as BackgroundSVG } from "../../assets/images/card-background.svg"

import {
  StyledCardMedium,
  StyledCardMedia,
  StyledCardTitle,
  StyledNavigationButton,
  StyledLink,
  StyledCardContent
} from "./StyledCard"
import { booleanToString } from "../../shared/tools/strings"

import StyledButton from "../Button"
import { CardProps, ActionProps, LinkProps } from "./types"
import HtmlRenderer from "../HtmlRenderer"

const CardComponent = ({
  id,
  title,
  content,
  actions,
  background,
  cardImage,
  image,
  links,
  customContent,
  fullWidth = false,
  hideButtonAction
}: CardProps) => {
  const backgroundOrDefault = background || <BackgroundSVG />

  return (
    <StyledCardMedium key={id} flexwidth={booleanToString(fullWidth)}>
      {!customContent && (
        <StyledCardMedia image={image}>
          {backgroundOrDefault}
          {cardImage}
        </StyledCardMedia>
      )}
      <StyledCardContent $addpadding={id !== "assignmentList"}>
        {title && (
          <StyledCardTitle variant="h6" component="h2" gutterBottom>
            {title}
          </StyledCardTitle>
        )}
        {content &&
          content.map((paragraph: string, index: number) => (
            <HtmlRenderer
              key={index}
              component={Typography}
              componentProps={{
                variant: "body2",
                color: "textSecondary",
                component: "p"
              }}
              html={paragraph}
            />
          ))}
        {customContent}
      </StyledCardContent>
      {(actions || links) && (
        <CardActions>
          {actions &&
            actions.map((a: ActionProps) => {
              if (a.to) {
                return (
                  <StyledNavigationButton
                    to={a.to}
                    key={a.caption}
                    tabIndex={0}
                  >
                    {a.caption}
                  </StyledNavigationButton>
                )
              }
              if (a.clickHandler) {
                return (
                  <StyledButton
                    key={a.caption}
                    onClick={() => a.clickHandler && a.clickHandler()}
                    color="primary"
                    tabIndex={-1}
                  >
                    {a.caption}
                  </StyledButton>
                )
              }

              return null
            })}
          {links &&
            links.map((l: LinkProps) => (
              <StyledLink
                variant="button"
                target="_blank"
                underline="none"
                href={l.href}
                key={l.caption}
                tabIndex={0}
              >
                {l.caption}
              </StyledLink>
            ))}
          {hideButtonAction && (
            <StyledButton
              onClick={hideButtonAction}
              color="primary"
              tabIndex={-1}
            >
              Dölj
            </StyledButton>
          )}
        </CardActions>
      )}
    </StyledCardMedium>
  )
}

export default CardComponent
