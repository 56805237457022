export type ModuleName = "users"
export const MODULE_NAME: ModuleName = "users"
export const SESAM_GROUP = "sesam:40676-WB01_tomoyo_matematik"

export enum USER_ROLE {
  STUDENT = "student",
  TEACHER = "teacher"
}

export const SETTING = {
  settingShowWelcomeBackBanner: "setting-show-welcome-back-banner",
  settingShowTipAssignment: "setting-show-tip-assignment",
  settingShowTipFormativeTest: "setting-show-tip-formative-test",
  settingShowCardWorkEfficient: "setting-show-card-work-efficient",
  settingShowCardTomoyoGetStarted: "setting-show-card-tomoyo-get-started",
  settingShowCardTomoyoGoals: "setting-show-card-tomoyo-goals",
  settingShowCardFormativeQuestions: "setting-show-card-formative-questions",
  settingShowCardJoinGroup: "setting-show-card-join-group",
  settingShowCardOverviewExercises: "setting-show-card-overview-exercises",
  settingShowCardAddAssignments: "setting-show-card-add-assignments",
  settingShowCardQuickTip: "setting-show-card-quick-tip"
} as const

export type ValueOf<T> = T[keyof T]
